@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #111;
  color: #fff;
  font-family: 'Walter Turncoat';
  scroll-behavior: smooth;
}

#root{
  min-height:100vh;
  display:flex;
  flex-direction: column;
  scrollbar-gutter: 0.5rem;
  cursor: url(../public/svg/cursor/cursor.svg), auto;
}

a{
  cursor: url(../public/svg/cursor/cursor-pointer.svg) 22 22, pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* 
###################################################################################################################################
###########                      ##################################################################################################
###########  Animation classes   ##################################################################################################
###########                      ##################################################################################################
###################################################################################################################################
*/

.open-scroll {
  animation: scroll-items-down 1s ease-out forwards;
}

.close-scroll {
  animation: scroll-items-up 1s ease-out forwards;
}

.fade-in {
  opacity:0;
  animation: fade-in 1s ease-out forwards;
}

.fade-out {
  animation: fade-out 1s ease-in forwards;
}


.typewriter{
  border:1px solid;
  animation: typing .5s ease infinite;
}

.bounce-in{
  scale:1 0;
  animation: bounce-in .5s linear forwards;
}


.stretch-in {
  scale:0 1;
  animation: stretch-in 1s ease forwards;
}

.stretch-out {
  animation: stretch-out 1s ease forwards;
}

.text-bounce {
  animation: text-bounce 3s ease-out infinite;
  transform-origin: bottom;
}

.blurr-fade-out{
  transform-origin:top;
  animation: blurr-fade-out 1s linear forwards;
}

.slider{
  transform-origin:left;
  animation:slide-left 0.8s linear forwards;
}

@media (max-width:768px) {
  .md-slider{
    transform-origin:top;
    animation:slide-up 0.8s linear forwards;
  }
}

/* 
###################################################################################################################################
###########                            ############################################################################################
###########  Tailwind custom classes   ############################################################################################
###########                            ############################################################################################
###################################################################################################################################
*/


@layer base {
  .reenie-beanie{
    font-family:'Reenie Beanie';
  }
  .walter-turncoat{
    font-family: 'Walter Turncoat';
  }
}


/* 
############################################################################################################################
###########                     ############################################################################################
###########  Custom Scrollbar   ############################################################################################
###########                     ############################################################################################
############################################################################################################################
*/

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #111;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 999999999px;
}


/* 
#######################################################################################################################
###########                ############################################################################################
###########  Animations    ############################################################################################
###########                ############################################################################################
#######################################################################################################################
*/

@keyframes scroll-items-down {
  from { 
    max-height:0px;
  }
  to {
    max-height:70vh;
  }
}
@keyframes scroll-items-up {
  from { 
    max-height:70vh;
  }
  to {
    max-height:0px;
  }
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

@keyframes typing {
  from {
    border-color:transparent;
  }
  to {
    border-color:#fff;
  }
}

@keyframes bounce-in {
  0% {
    scale:1 0;
  }
  95% {
    scale:1 1.3;
  }
  100% {
    scale:1;
  }
}

@keyframes stretch-in {
  0% {
    scale:0 1;
  }
  100% {
    scale:1 1;
  }
}

@keyframes stretch-out {
  0% {
    scale:1 1;
  }
  100% {
    scale:0 1;
  }
}


@keyframes text-bounce {
  2.5% {
      transform: scale(1.3, 0.5) translateY(0px);
  }
  5% {
      transform: scale(0.7, 1.3) translateY(0px);
  }
  7.5% {
      transform: translateY(-20%) scale(1, 1);
  }
  12.5% {
      transform: translateY(-40%) scale(1, 1);
  }
  13% {
      transform-origin: top;
      transform: translateY(-40%) scale(0.8, 1.2);
  }
  20% {
      transform: translateY(0px) scale(1, 1);
      transform-origin: bottom;
  }
  22.5% {
      transform: translateY(0px) scale(1.3, 0.5);
  }
  25% {
      transform: scale(1, 1) translateY(0px);
  }
  100% {
      transform: scale(1, 1) translateY(0px);
  }
}

@keyframes blurr-fade-out {
  50%{
    filter:blur(10px);
  }
  100%{
    filter:blur(10px);
    opacity:0;
  }
}

@keyframes slide-left {
  0%{
    z-index:-10;
  }
  100%{
    z-index:-10;
    translate:-100% 0px;
  }
}
@keyframes slide-up {
  0%{
    z-index:-10;
  }
  100%{
    z-index:-10;
    translate:0px -100%;
  }
}



